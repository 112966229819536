var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all-fee-students-page"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"6"}},[_c('intract-smart-list',{ref:"all-fee-students-smart-list",staticClass:"mt-4",attrs:{"paginated":"","endpoint":_vm.feeStudentsEndpoint,"filter-fields":_vm.filterFields,"item-options":{
          title: 'user.full_name',
          avatar: 'user.image',
          subtitle: 'user.username',
          handleClick: function (item) { return _vm.$router.push({
              name: 'StudentFeeDetails',
              params: { studentId: item.user.id },
            }); },
        }},scopedSlots:_vm._u([{key:"list-item-content",fn:function(ref){
        var feeDetail = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(feeDetail.user.full_name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(feeDetail.user.username))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(feeDetail.user.room.sections))])],1)]}},{key:"list-item-right",fn:function(ref){
        var studentFeeAccount = ref.item;
return [_c('v-list-item-icon',{staticClass:"align-self-center ma-0"},[_c('div',{staticClass:"text-center"},[(studentFeeAccount.due_date != null)?_c('div',{staticClass:"ma-0 pa-0 text-caption font-weight-bold"},[(_vm.moment(studentFeeAccount.due_date).isAfter(_vm.moment()))?_c('p',{staticClass:"ma-0 pa-0 text-caption"},[_vm._v("Next Due")]):_c('p',{staticClass:"ma-0 pa-0 text-caption"},[_vm._v("Overdue since")]),_c('span',{class:_vm.moment(studentFeeAccount.due_date).isAfter(_vm.moment())
                      ? 'primary--text'
                      : 'red--text'},[_vm._v(_vm._s(_vm.moment(studentFeeAccount.due_date).format("ll")))])]):(studentFeeAccount.total_fee == 0)?_c('div',{staticClass:"ma-0 pa-0 text-caption"},[_vm._v(" Not Set ")]):(studentFeeAccount.total_paid >= studentFeeAccount.total_fee  - ( studentFeeAccount.concession || 0 ))?_c('div',[_c('p',{staticClass:"green--text text-center text-caption font-weight-bold"},[_vm._v("Fully Paid")])]):_c('div',{staticClass:"ma-0 pa-0 text-caption font-weight-bold red--text"},[_vm._v(" - ")])])])]}},{key:"custom-filter--status",fn:function(){return [_c('v-select',{ref:"select-filter--payment-status",staticClass:"rounded-input",attrs:{"items":_vm.filterFields.status.items,"small-chips":"","deletable-chips":"","single-line":"","solo":"","color":"primary","label":"Filter by Payment Status","hide-details":"","background-color":"transparent","item-value":"value","item-text":"title"},on:{"change":function($event){return _vm.$refs['select-filter--payment-status'].blur()}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})]},proxy:true}])})],1)],1),_c('intract-create-edit-sheet',{key:"fee-details-create-edit-sheet",ref:"fee-details-create-edit-sheet",attrs:{"title":_vm.createEditFeeDetailsSheet.editId
        ? 'Edit Fee Details'
        : 'Create Fee Details',"description":"Manage your students' fee details with just few clicks","persistent":"","visible":_vm.createEditFeeDetailsSheet.visible,"fields":_vm.formFields,"endpoint":_vm.endpoints.studentFeesDetailViewSet,"edit-id":_vm.createEditFeeDetailsSheet.editId,"data-object":_vm.createEditFeeDetailsSheet.obj,"create-success-message":_vm.createEditFeeDetailsSheet.createSuccessMessage,"edit-success-message":_vm.createEditFeeDetailsSheet.editSuccessMessage,"getObject":_vm.finalOperationsOnFormObject},on:{"close":function($event){_vm.createEditFeeDetailsSheet.selectedUser = null;
      _vm.createEditFeeDetailsSheet.visible = false;
      _vm.createEditFeeDetailsSheet.editId = null;},"objectCreated":_vm.onRefresh,"updateObject":function (obj) { return (_vm.createEditFeeDetailsSheet.obj = obj); }},scopedSlots:_vm._u([{key:"custom-field--user",fn:function(ref){
      var obj = ref.obj;
return [_c('v-text-field',{attrs:{"required":true,"hint":_vm.formFields.user.helper,"persistent-hint":"","outlined":"","type":"text","hide-details":"auto","value":obj.user ? obj.user.full_name : '',"readonly":"","disabled":_vm.createEditFeeDetailsSheet.editId != null},on:{"click":function($event){_vm.showUserSelectionSheet = true}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.formFields.user.label)),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v(" * ")])])]},proxy:true}],null,true)}),_c('intract-list-sheet',{attrs:{"title":"Select Student","description":_vm.formFields.user.helper,"visible":_vm.showUserSelectionSheet},on:{"close":function($event){_vm.showUserSelectionSheet = false}},scopedSlots:_vm._u([{key:"list",fn:function(){return [_c('intract-smart-list',{staticClass:"mt-2",attrs:{"endpoint":_vm.studentsWithoutFeeDetailsEndpoint,"paginated":"","item-options":{
              title: 'full_name',
              avatar: 'image',
              handleClick: function (student) { return _vm.handleStudentSelectionClick(student, obj); },
            }},scopedSlots:_vm._u([{key:"list-item-content",fn:function(ref){
            var student = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(student.full_name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(student.username))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(student.room.sections))])],1)]}}],null,true)})]},proxy:true}],null,true)})]}}])}),_c('transition',{attrs:{"name":"slide-y-reverse-transition"}},[(!_vm.isLoading && _vm.showFab && ( _vm.currentUser.is_admin || _vm.allRooms.find(function (room) { return room.class_teachers && room.class_teachers.map(function (ct) { return ct.id; }).includes(_vm.currentUser.id); })))?_c('v-btn',{staticClass:"extended-fab non-focused",attrs:{"color":"primary","dark":"","right":"","bottom":"","fixed":"","fab":""},on:{"click":function($event){_vm.createEditFeeDetailsSheet.visible = true}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-plus")]),_vm._v("Add New ")],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }